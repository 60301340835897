<template>
  <div ref="QRCode"></div>
</template>
<script>
import QRCode from 'qrcodejs2'

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '140'
    },
    height: {
      type: String,
      default: '140'
    },
    colorDark: {
      type: String,
      default: '#333333'
    },
    colorLight: {
      type: String,
      default: '#ffffff'
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      CorrectLevel: {
        1: QRCode.CorrectLevel.L,
        2: QRCode.CorrectLevel.M,
        3: QRCode.CorrectLevel.Q,
        4: QRCode.CorrectLevel.H
      },
    }
  },
  mounted() {
    this.createQrcode()
  },
  methods: {
    createQrcode() {
      new QRCode(this.$refs.QRCode, {
        text: this.url,
        width: this.width,
        height: this.height,
        colorDark: this.colorDark,
        colorLight: this.colorLight,
        correctLevel: this.CorrectLevel[this.level],
      })
    }
  }
}
</script>
