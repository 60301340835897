<template>
  <div class="mian">
    <div class="dashboard">
      <a class="ant-dropdown-link" @click="e => e.preventDefault()">
        个人中心
      </a>
      <div class="qrcode" v-if="['prod','pre', 'prod_kunda', 'prod_bj'].includes(envKey)">
        <a-dropdown>
          <img class="qrcode" :src="$toUrl('qrcode.png')" />
          <a-menu slot="overlay">
            <a-menu-item style="padding: 15px;border-radius: 5px">
              <QRCode :url="QRCodeUrl" />
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <span>扫码授权</span>
      </div>
    </div>
    <div class="container">
      <a-form-model ref="form" layout="horizontal" labelAlign="left" :model="pwdForm" :rules="rules" style="width: 40%"
                    :label-col="{span: 3}" :wrapper-col="{span: 8}">
        <a-form-model-item label="头像">
          <!--          <a-input v-model="form.issue_name"></a-input>-->
          <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :customRequest="avatarUploader"
              @change="handleChange"
          >
            <img v-if="form.pic" :src="$toUrl(form.pic)" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                请上传头像
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="姓名">
          <a-input v-model="form.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户名">
          <a-input v-model="form.username"></a-input>
        </a-form-model-item>
        <a-form-model-item label="邮箱">
          <a-input v-model="form.email"></a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号">
          <a-input v-model="form.mobile"></a-input>
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="user_password">
          <a-input-password type="password" autocomplete="new-password"
                            v-model="pwdForm.user_password"></a-input-password>
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="check_password">
          <a-input-password type="password" autocomplete="new-password"
                            v-model="pwdForm.check_password"></a-input-password>
        </a-form-model-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 0 }">
          <a-button type="primary" @click="save">
            保存
          </a-button>
        </a-form-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import Storage from "@/utils/storage";
import { fetch } from "@/utils/request";
import { JSEncrypt } from "jsencrypt";
import QRCode from "@/components/QRCode/QRCode.vue";
import { envKey } from "@/utils/appsettings";

export default {
  name: "personalCenter",
  components: { QRCode },
  data() {
    return {
      form: {
        pic: '',
        name: '',
        username: '',
        mobile: '',
        email: ''
      },
      pwdForm: {
        user_password: '',
        check_password: ''
      },
      rules: {
        check_password: [{ required: true, validator: this.validatePass2, trigger: 'change' }],
      },
      loading: false,
      public_key: '',
      company_info: {
        account_oid: ''
      },
      QRCodeUrl: '',
      envKey
    }
  },
  created() {
    this.company_info = Storage.getItem('company_info')
    this.getQRCode()
  },
  mounted() {
    this.getMyconfig('global_config', 'public_key')
    this.getAccountInfo(this.company_info.account_oid)
  },
  methods: {
    // 获取微信认证二维码
    async getQRCode() {
      const id = JSON.parse(localStorage.getItem("company_info")).account_oid + `;${envKey}`
      console.log(id)
      const res = await fetch('post', '/account/wx/web/author/qrcode', { state: id })
      console.log('获取微信认证二维码', res)
      if (res.status === 0) {
        this.QRCodeUrl = res.data
      }
    },
    handleChange(info) {
      // console.log(info);
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('您只能上传图片！');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('图片必须小于2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    avatarUploader(data) {
      console.log(data);
      let formData = new FormData()
      const file = data.file
      // formData.append('source_oid', this.$genGuid())
      // formData.append('sec_level', 0)
      formData.append('file', file)
      //头像不应该上传到com_file, 而应该到 ua 里面，这里新开一个api，专门上传ua文件，wanli
      fetch('post', '/attachment/upload/ua', formData).then((res) => {
        if (res.status === 0) {
          console.log(res.data);
          this.form.pic = res.data.file_path;
          this.loading = false;
          this.$message.success('上传成功！')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getMyconfig(type, code) {
      let url = '/cpy/cat/retrieveportal/global/pub'
      fetch('post', url, {
        cat_type: type,
        cat_code: code
      }).then((res) => {
        if (res && res.status === 0) {
          if (res.data.datas.length > 0) {
            this.public_key = res.data.datas[0].field_value
            // console.log(this.public_key);
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getAccountInfo(oid) {
      fetch('post', '/cpyaccount/info', {
        oid
      }).then((res) => {
        if (res && res.status === 0) {
          this.form = {
            ...res.data
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    async save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          console.log(valid)
          let pwdupdate = { status: 0 }
          let update = await fetch('post', '/cpyaccount/update', { oid: this.company_info.account_oid, ...this.form })
          if (this.pwdForm.user_password && this.pwdForm.check_password) {
            let encryptor = new JSEncrypt() // 新建JSEncrypt对象
            encryptor.setPublicKey(this.public_key) // 设置公钥
            let PassWord = encryptor.encrypt(this.pwdForm.user_password) // 对密码进行加密
            let query = {
              user_password: PassWord,
              oid: this.company_info.account_oid
            }
            pwdupdate = await fetch('post', '/cpyaccount/pwd/update', query)
            if (pwdupdate.status !== 0) {
              this.$message.error(pwdupdate.message)
              return false
            }
          }
          if (update.status === 0 && pwdupdate.status === 0) {
            let company_info = JSON.parse(localStorage.getItem('company_info'))
            if (this.form.pic !== company_info.picture) {
              company_info.picture = this.form.pic
              localStorage.setItem("company_info", JSON.stringify(company_info))
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }
            this.$message.success('保存成功！')
          } else {
            this.$message.error('保存失败！')
          }
        } else {
          return false;
        }
      })
    },
    validatePass2(rule, value, callback) {
      if (value !== this.pwdForm.user_password) {
        callback(new Error('两次输入的密码不一致！'));
      } else {
        callback();
      }
    }
  }
}
</script>

<style scoped lang="less">
.mian {
  padding: 14px 20px 14px 20px;

  .dashboard {
    padding: 0 15px 0 20px;
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.50);
    margin-bottom: 14px;

    .ant-dropdown-link {
      font-size: 20px;
      font-weight: 500;
      color: #4D4D4D;
    }

    .qrcode {
      display: flex;
      align-items: center;

      span {
        margin-left: 12px;
        font-size: 16px;
        font-weight: 500;
        color: #1D7BFF;
      }
    }
  }

  /deep/ .container {
    padding: 15px 15px 0 20px;
    min-height: 780px;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.5);

    .ant-upload {
      border-radius: 50%;
      padding: 0;
      margin: 0;

      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

}
</style>
